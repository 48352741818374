import { domainPermission, viewAccess } from "@/utils/utils";
var permission = domainPermission("Common");

var navMenu = [];
var menu = [
  {
    title: "Dashboard",
    icon: "ActivityIcon",
    children: [
      {
        title: "Machine Dashboard",
        route: "machine-dashboard",
        icon: "ActivityIcon",
      },
      // {
      //   title: "Manufacture",
      //   route: "dashboard-manufacture",
      //   icon: "ActivityIcon",
      // },
      // {
      //   title: "Commerce",
      //   route: "dashboard-commerce",
      //   icon: "ActivityIcon",
      // },
    ],
  },
  {
    title: "Master Data",
    icon: "FolderIcon",
    isMainMenu: true,
    route: "item",
    children: [
      {
        title: "Picklist History",
        route: "picklist-history",
        icon: "PackageIcon",
      },
      {
        title: "Baby Drum",
        route: "baby-drum",
        icon: "PackageIcon",
      },
      {
        title: "Hardware",
        route: "hardware",
        icon: "ToolIcon",
      },
      {
        title: "Station",
        route: "station",
        icon: "HomeIcon",
      },
      // {
      //   title: "Instruction",
      //   route: "instruction",
      //   icon: "FileIcon",
      // },
      {
        title: "Reason List",
        route: "reason-list",
        icon: "FileIcon",
      },
    ],
  },
  // {
  //   navId: "purchase",
  //   title: "Purchase",
  //   route: "purchase",
  //   icon: "ShoppingCartIcon",
  //   role: "Admin",
  // },
  // {
  //   navId: "sales",
  //   title: "Sales",
  //   route: "sales",
  //   icon: "ShoppingBagIcon",
  //   role: "Admin",
  // },
  {
    title: "Job Order",
    // route: "job-order",
    icon: "ListIcon",
    children: [
      {
        title: "Ongoing Job",
        route: "ongoing-job-order",
        icon: "PlayIcon",
      },
      {
        title: "Completed Job ",
        route: "completed-job-order",
        icon: "CheckIcon",
      },
    ],
  },
  {
    title: "Drum Picking",
    // route: "drum-picking",
    icon: "TargetIcon",
    children: [
      {
        title: "Ongoing Drum Picking",
        route: "ongoing-drum-picking",
        icon: "PlayIcon",
      },
      {
        title: "Completed Drum Picking ",
        route: "completed-drum-picking",
        icon: "CheckIcon",
      },
    ],
  },
  {
    title: "Direct Sales",
    route: "direct-sales",
    icon: "ArrowRightIcon",
  },
  {
    title: "Machine",
    route: "machine",
    icon: "VoicemailIcon",
  },
  {
    title: "Recoil",
    route: "recoil",
    icon: "RefreshCwIcon",
  },
  // {
  //   title: "Hardware Testing",
  //   route: "hardware-testing",
  //   icon: "Edit3Icon",
  // },
  {
    title: "Packing",
    // route: "packing",
    icon: "PackageIcon",
    children: [
      {
        title: "Ongoing Packing",
        route: "ongoing-packing",
        icon: "PlayIcon",
      },
      {
        title: "Completed Packing",
        route: "completed-packing",
        icon: "CheckIcon",
      },
    ]
  },
  {
    title: "Delivery Picking",
    // route: "delivery-picker",
    icon: "TruckIcon",
    children: [
      {
        title: "Ongoing Delivery Picking",
        route: "ongoing-delivery-picking",
        icon: "PlayIcon",
      },
      {
        title: "Completed Delivery Picking",
        route: "completed-delivery-picking",
        icon: "CheckIcon",
      },
    ]
  },
  {
    title: "Role",
    route: "role",
    icon: "SettingsIcon",
  },
  {
    title: "Pause Setting",
    route: "pause-setting",
    icon: "ClockIcon",
  },
  {
    title: "User",
    route: "user",
    icon: "UsersIcon",
  },
];

if (permission.allAccess) {
  navMenu = [...menu];
} else {
  var userAccess = viewAccess();

  menu.forEach((item) => {
    if (item.children && item.children.length > 0) {
      var hasAccess = item.children.some(child => userAccess.includes(child.route));
      if (hasAccess) {
        navMenu.push({
          title: item.title,
          icon: item.icon,
          children: item.children.filter(child => userAccess.includes(child.route))
        });
      }
    } else if (userAccess.includes(item.route)) {
      navMenu.push(item);
    }
  });
  console.log(navMenu);
}

// navMenu.push({
//   title: 'OEE',
//   route: 'oee',
//   icon: "TargetIcon"
// })

export default navMenu;
